import _ from 'lodash';

import img_cashradar from './logo/cashradar.png'
import img_ccloan from './logo/ccloan.png'
import img_forzaem from './logo/forzaem.png'
import img_getmoney from './logo/getmoney.png'
import img_gozaym from './logo/gozaym.png'
import img_mobilezaim from './logo/mobilezaim.png'
import img_tengebai from './logo/tengebai.png'
import img_zaimfast from './logo/zaimfast.png'
import img_zaimyvsem from './logo/zaimyvsem.png'
import {Card} from './Card';

import cls from './Cards.module.scss'

export interface IOffer {
    name: string
    logo: string
    percent: string
    date: string
    value: string
    age: string
    URL: string
}

const items:IOffer[] = [
    {
        name: 'ZaimFast',
        logo: img_zaimfast,
        percent: '0,99% в день',
        date: 'до 30 дней',
        value: 'до 500 000 ₸',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=106123&offer_id=6234',
    },
    {
        name: 'MobileZaim',
        logo: img_mobilezaim,
        percent: '0,99% в день',
        date: 'до 30 дней',
        value: 'до 500 000 ₸',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=106123&offer_id=6196',
    },
    {
        name: 'ZaimyVsem',
        logo: img_zaimyvsem,
        percent: '0,99% в день',
        date: 'до 30 дней',
        value: 'до 500 000 ₸',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=106123&offer_id=6469',
    },
    {
        name: 'GetMoney',
        logo: img_getmoney,
        percent: 'от 0,99% в день',
        date: 'до 30 дней',
        value: 'до 170 000 ₸',
        age: 'от 18 лет',
        URL: 'https://togetmoney.kz',
    },
    {
        name: 'Tengebai',
        logo: img_tengebai,
        percent: 'от 0,99% в день',
        date: 'до 30 дней',
        value: 'до 184 000 ₸',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=106123&offer_id=6322',
    },
    {
        name: 'Ccloan',
        logo: img_ccloan,
        percent: '0,99% в день',
        date: 'до 20 дней',
        value: 'до 184 000 ₸',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=106123&offer_id=3427',
    },
    {
        name: 'GoZaym',
        logo: img_gozaym,
        percent: '0,99% в день',
        date: 'от 3 до 30 дней',
        value: 'до 500 000 ₸',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=106123&offer_id=6529',
    },
    {
        name: 'CashRadar',
        logo: img_cashradar,
        percent: '0,99% в день',
        date: 'до 1 года',
        value: 'до 500 000 ₸',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=106123&offer_id=6529',
    },
    {
        name: 'Forzaem',
        logo: img_forzaem,
        percent: '0,99% в день',
        date: 'до 1 года',
        value: 'от 10 000 ₸ до 300 000 ₸',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=106123&offer_id=6670',
    },

]

export const Cards = () => (
    <div className={cls.cards}>
        {_.map(items, (item) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Card key={item.name} {...item}/>
        ))}
    </div>
)