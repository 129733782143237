import {useEffect, useState} from 'react';
import {useInstance} from 'react-ioc';
import {RightOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import {Button, Checkbox, Form, Input} from 'antd'
import {MaskedInput} from 'antd-mask-input';
import dayjs from 'dayjs'
import {observer} from 'mobx-react-lite';

import {POLICY} from '../../config/routes';
import {Store} from '../../model/store/Store';
import {sendCodeKZ, sendCodeKZVariables} from '../../shared/graphql/__generated__/sendCodeKZ';
import {SEND_CODE_KZ} from '../../shared/graphql/sendCodeKZ';
import {phoneRegex} from '../../shared/helpers/regex';

import cls from './Registration.module.scss'

interface IFormValues {
    FIO: string;
    Phone: string;
}

interface IForm {

    onComplete: (values: IFormValues) => void

}

export const RegForm = observer(({onComplete}: IForm) => {

    const [form] = Form.useForm()
    const [agree, setAgree] = useState<boolean>(true)

    const [wait, setWait] = useState<boolean>(false)

    const {auth, lead} = useInstance(Store)

    const [sendCode] = useMutation<sendCodeKZ, sendCodeKZVariables>(SEND_CODE_KZ)

    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const onFinish = async (values: IFormValues) => {
        await sendCode({
            variables: {
                data: {
                    FIO: values.FIO,
                    Phone: values.Phone,
                    click_id: lead.click_id,
                }
            },
            onCompleted: () => {
                auth.setData(values.FIO, values.Phone, dayjs().format())
                onComplete(values)
            },
            onError: (error) => {
                console.log(error.message)
                if (error.message === 'Await') {
                    auth.setData(values.FIO, values.Phone, dayjs().format())
                    setWait(true)
                }
            }
        })
    }

    const awaitSeconds = dayjs(auth.SendingDate).diff(dayjs().add(-5, 'minutes'), 'seconds')
    console.log(time)

    return (
        <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onSubmit'} onFinish={onFinish}
              className={cls.form}>
            <Form.Item name={'FIO'} label={'ФИО'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'Иванов Иван Иванович'}/>
            </Form.Item>
            <Form.Item name={'Phone'} label={'Номер телефона'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: phoneRegex, message: 'Не соответствует формату номера телефона'}
            ]}>
                <MaskedInput mask={'+7 (000) 000-00-00'} placeholder={'+7 (701) 234-56-78'} size={'large'}/>
            </Form.Item>
            <Form.Item>
                <Checkbox checked={agree} onChange={() => setAgree(!agree)}>Согласен на обработку <a href={POLICY}
                                                                                                     rel={'noreferrer'}
                                                                                                     target={'_blank'}>персональных
                    данных и с политикой конфиденциальности</a></Checkbox>
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} disabled={!agree} icon={<RightOutlined/>}>Получить
                    код</Button>
            </Form.Item>
            {wait &&
                <Form.Item>
                    <div className={'caption'}>
                        {awaitSeconds > 0 &&
                            `Отправить повторно через ${Math.floor(awaitSeconds/60)}:${awaitSeconds%60}`
                        }
                    </div>
                </Form.Item>
            }
        </Form>
    )
})
