import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Slider} from 'antd';
import cn from 'classnames';
import currency from 'currency.js'

import {REGISTRATION} from '../../../config/routes';

import img from './cover-kz.jpg';
import img_mobile from './cover-kz-mobile.jpg';

import cls from './Banner.module.scss'

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

const CurrencyFormat = (value: number | string) =>
    currency(value, {
        symbol: '',
        separator: ' ',
        precision: 0,
    }).format();

export const Banner = () => {

    const [zaim,setZaim] = useState<number>(85000)
    const navigate = useNavigate()

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={cls.banner} style={{backgroundImage: `url(${width > 600 ? img : img_mobile})`}}>

            <div className={cn(['highlight', cls.title])}>Получите онлайн займ под 0,01%</div>

            <div className={cls.slider}>
                <div className={cls.label}>
                    <div>Сумма займа</div>
                    <div className={'bold'}>{CurrencyFormat(zaim)} ₸</div>
                </div>
                <Slider value={zaim} onChange={(value) => setZaim(value)} min={10000} max={200000} step={1000}/>
                <div className={cn([cls.label, 'caption'])}>
                    <div>10&nbsp;000&nbsp;₸</div>
                    <div>200&nbsp;000&nbsp;₸</div>
                </div>
            </div>

            <div><Button type={'primary'} size={'large'} onClick={() => navigate(REGISTRATION)}>Получить займ</Button></div>

        </div>
    )
}