import {applySnapshot, types as t,} from 'mobx-state-tree';

export const initialLeadStore = {
    UID: null,
    click_id: null,
};

export const LeadStore = t
    .model({
        UID: t.maybeNull(t.string),
        click_id: t.maybeNull(t.string),
    })
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialLeadStore);
        },
        setClickID: (click_id: string | null) => {
            self.click_id = click_id;
        },
        setUID: (UID: string | null) => {
            self.UID = UID;
        },
    }));
