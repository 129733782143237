import React from 'react';
import {Link} from 'react-router-dom';

import { INDEX} from '../../config/routes';
import logo from '../../images/OnlyCash-Logo-White.svg'

import cls from './Footer.module.scss'

const Footer = () => (
        <div className={cls.footer}>
            <div className={cls.content}>

                <div className={cls.logo}>
                    <Link to={INDEX}>
                        <img src={logo} alt={'logo'}/>
                    </Link>
                    <div className={cls.caption}>
                        <p>© Only Cash, 2024</p>
                        <p>Все права защищены</p>
                    </div>
                </div>


                <div className={cls.policy}>
                    <div>
                        Сайт OnlyCash не является финансовым учреждением и не занимается выдачей различных кредитных продуктов пользователям сайта (кредитов и займов). Проект выступает в качестве агрегатора финансовых продуктов, представленных различными кредитными организациями, которые имеют все обязательные лицензии и сертификаты. Сайт не несет ответственность за условия, представленные кредиторами, а также за любые заключенные договоры кредитования. Расчеты на сайте носят приблизительный характер, окончательные условия по услугам микрофинансирования уточняйте на сайте кредитной организации.
                    </div>
                    <div className={cls.legal}>
                        <span>ТОО «АйТи Консул»</span>
                        <span>БИН: 230740027370</span>
                        <span>Тел: +7 (702) 031 25 26</span>
                    </div>
                    <div>Казахстан, город Алматы, Ауэзовский район, улица Рыскулбекова, здание 39а, почтовый индекс 050042</div>

                </div>



            </div>
        </div>
    )

export default Footer